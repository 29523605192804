import { Link, useNavigate, useParams } from "react-router-dom"
import { LeavePageWarningPrompt } from "../../Others/LeavePageWarningPrompt"
import { PageTitleBox } from "../../Others/PageTitleBox"
import ProductCollectionStore from "../../Stores/ProductCollectionStore"
import { inject, observer } from "mobx-react"
import { SectionTitle } from "../../Others/SectionTitle"
import { useEffect, useRef, useState } from "react"
import IProduct from "../../../Interfaces/IProduct"
import CurrentUserStore from "../../Stores/CurrentUserStore"
import WebsiteStore from "../../Stores/WebsiteStore"
import { concat, filter } from "lodash"
import GlobalSettingsStore from "../../Stores/GlobalSettingsStore"
import ListsStore from "../../Stores/ListsStore"
import { ListType } from "../../../Enums/ListType"
import { IValidationFormRef } from "../../../Interfaces/IValidationFormRef"
import { ValidationHelper } from "../../Utils/ValidationHelper"
import { ProductImagesEditor } from "../../Uploader/Products/Create/ProductImagesEditor"
import ProductImageStore from "../../Stores/ProductImageStore"
import Swal from "sweetalert2"
import { SelectMarketingChannelsCard } from "../../Products/OnePageCreateEdit/SelectMarketingChannelsCard"
import { BasicInfoFormCard } from "../../Products/OnePageCreateEdit/BasicInfoFormCard"
import { PricesFormCard } from "../../Products/OnePageCreateEdit/PricesFormCard"
import { BusinessInfoFormCard } from "../../Products/OnePageCreateEdit/BusinessInfoFormCard"
import ProductStore from "../../Stores/ProductStore"
import { productStore } from "../../../App"
import ProductApiService from "../../../Services/ProductApiService"
import LoadingScreen from "../../Screens/LoadingScreen"
import { NotFound } from "../NotFound"
import { ProductHelper } from "../../../Helpers/ProductHelper"
import { Alert } from "react-bootstrap"
import FacebookCatalogsApiService from "../../../Services/FacebookCatalogsApiService"
import IFormError from "../../../Interfaces/IFormError"
import { FormErrorSummary } from "../../Others/FormErrorSummary"
import { AluminiumWheelsFormCard } from "../../Products/OnePageCreateEdit/AluminiumWheelsFormCard"
import { ProductSubcategory } from "../../../Enums/ProductSubcategory"
import { VehicleType } from "../../../Enums/VehicleType"
import { ProductType } from "../../../Enums/ProductType"
import { TiresFormCard } from "../../Products/OnePageCreateEdit/TiresFormCard"
import IInputField from "../../../Interfaces/IInputField"
import { CarsFormCard } from "../../Products/OnePageCreateEdit/CarsFormCard"
import { MotoFormCard } from "../../Products/OnePageCreateEdit/MotoFormCard"
import FacebookApiService from "../../../Services/FacebookApiService"
import { IFacebookCategory } from "../../../Interfaces/IFacebookCategory"
import GoogleApiService from "../../../Services/GoogleApiService"
import { IGoogleCategory } from "../../../Interfaces/IGoogleCategory"
import { toast } from "react-toastify"
import { CategoriesFormCard } from "../../Products/OnePageCreateEdit/CategoriesForm"
import { IServersFormRef, ServersFormCard } from "../../Products/OnePageCreateEdit/ServersForm"
import { IField } from "../../../Interfaces/IField"
import { WaitScreen } from "../../Screens/WaitScreen"

interface IProps {
    productCollectionStore?: ProductCollectionStore,
    currentUserStore?: CurrentUserStore,
    websiteStore?: WebsiteStore,
    globalSettingsStore?: GlobalSettingsStore,
    listsStore?: ListsStore,
    productImageStore?: ProductImageStore,
    productStore?: ProductStore,
}

const productApiService = new ProductApiService();
const facebookCatalogsApiService = new FacebookCatalogsApiService();
const facebookApiService = new FacebookApiService();
const googleApiService = new GoogleApiService();

export const ProductEditPage = inject(
    'productCollectionStore',
    'currentUserStore',
    'websiteStore',
    'globalSettingsStore',
    'listsStore',
    'productImageStore',
    'productStore')(observer((props: IProps) => {

        const { id } = useParams();
        const [loading, setLoading] = useState(true);
        const [product, setProduct] = useState<IProduct>(null);
        const [formErrors, setFormErrors] = useState<IFormError[]>([]);
        const [requiredFields, setRequiredFields] = useState<string[]>([]);
        const productServer = productStore.getById(id);
        const [fbCategories, setFbCategories] = useState<IFacebookCategory[]>([]);
        const [googleCategories, setGoogleCategories] = useState<IGoogleCategory[]>([]);
        const [deleteImagesLoading, setDelImagesLoading] = useState(false);

        useEffect(() => {
            window.scroll(0, 0);

            Promise.all([
                productApiService.getProduct(id),
                facebookCatalogsApiService.getCatalogs(),
                facebookApiService.getCategories(),
                googleApiService.getCategories(),
                productApiService.getRequiredFieldsById(id)
            ])
                .then(([productData, catalogsData, fbCategories, gCategories, requiredFieldsData]) => {
                    // Handle product data and catalogs data here
                    var p = productData.records.products.items[0];

                    setProduct(p);
                    setFbCategories(fbCategories);
                    setGoogleCategories(gCategories);
                    setRequiredFields(requiredFieldsData.others.get("requiredFields"));
                })
                .catch(error => {
                    // Handle errors here
                })
                .finally(() => {
                    setLoading(false);
                });

            return () => {
                productApiService.cancelRequests();
                facebookCatalogsApiService.cancelRequests();
                facebookApiService.cancelRequests();
            }
        }, [])

        const {
            productCollectionStore,
            currentUserStore,
            globalSettingsStore,
            listsStore,
            productImageStore
        } = props;

        const collection = productCollectionStore.getById(productServer?.productCollectionId);
        const user = currentUserStore.user;
        const globalSettings = globalSettingsStore.settings;

        const onChange = (key: string, value: any, p?: IProduct): IProduct => {
            var productM = ProductHelper.onChangeAutoProcess(p ?? product, key, value);
            setProduct(productM)

            if (key === 'section' && product.section) {
                Swal.fire({
                    icon: 'question',
                    title: "Chcete přepsat zařazení inzerátu?",
                    text: 'Zařazení inzerátu resetujeme a pokusíme se jej znovu doplnit dle nové sekce',
                    confirmButtonText: "Ano, přepsat",
                    cancelButtonText: "Nechat stávající",
                    showCancelButton: true,
                    allowOutsideClick: true,
                }).then(async response => {
                    if (response.value) {
                        productM.bazosCzSection = null;
                        productM.bazosCzCategory = null;
                        productM.bazosSkSection = null;
                        productM.bazosSkCategory = null;
                        productM.sbazarCategoryId = null;
                        productM.googleProductCategoryId = null;
                        productM.facebookCatalogId = null;
                    }
                    setProduct(productM)
                    getMissingFields(false, productM);
                });
            }
            return productM;
        }

        const getMissingFields = (ai: boolean, p: IProduct) => {
            productApiService.getMissingFields(p, ai)
                .then(data => {
                    var fields = data.others.get("fields") as IField;

                    const count = Object.entries(fields).length;
                    setProduct(currentProduct => {
                        var p = currentProduct;
                        Object.entries(fields).forEach(([key, value]) => {
                            p = ProductHelper.onChangeAutoProcess(p, key, value);
                        });
                        return p;
                    });
                });
        }

        const onChangeMarketingChannels = (key: string, value: any) => {
            var productModifed = onChange(key, value);

            productApiService.getRequiredFields(productModifed)
                .then(data => {
                    setRequiredFields(data.others.get("requiredFields"));
                })
        }

        const pricesFormRef = useRef<IValidationFormRef>(null);
        const basicFormRef = useRef<IValidationFormRef>(null);
        const businessInfoFormRef = useRef<IValidationFormRef>(null);
        const tiresFormRef = useRef<IValidationFormRef>();
        const aluminiumWheelsFormRef = useRef<IValidationFormRef>();
        const carsFormRef = useRef<IValidationFormRef>();
        const motoFormRef = useRef<IValidationFormRef>();
        const categoriesFormRef = useRef<IValidationFormRef>(null);
        const marketingChannelsRef = useRef<IValidationFormRef>(null);
        const serversFormRef = useRef<IServersFormRef>(null);

        const subcategories = listsStore.getOptionsBy(ListType.Subcategories);
        const images = filter(productImageStore.list, { productId: product?.id });

        const navigate = useNavigate();
        const [redirectToCollection, setRedirectToCollection] = useState(false);

        const handleSaveChangesAsync = async (enable: boolean) => {
            const isValid = await validateAsync();

            if (isValid) {
                productApiService.edit(id, product, enable)
                    .then((data) => {
                        if (!data.success) {
                            setFormErrors(data.formErrors);
                            toast.error("Změny nebyly uloženy");
                        }
                        else {
                            pushToCollection();
                        }
                    })
            }
        }

        useEffect(() => {
            if (redirectToCollection) {
                navigate(`/nahravac/kolekce/${collection.id}/prehled`);
            }
        }, [redirectToCollection])


        const pushToCollection = () => {
            setBlockLeave(false);
            setRedirectToCollection(true);
        }

        const [blockLeave, setBlockLeave] = useState(true);

        const validateAsync = async (): Promise<boolean> => {
            setFormErrors([]);

            let isValid = true;

            isValid = ValidationHelper
                .validateInputs(ValidationHelper.safeGetInputs(marketingChannelsRef));

            if (isValid) {
                const val = await basicFormRef.current.isContentValidAsync();
                isValid = val;
            }

            var inputs: React.MutableRefObject<IInputField>[] = concat(
                ValidationHelper.safeGetInputs(basicFormRef),
                ValidationHelper.safeGetInputs(pricesFormRef),
                ValidationHelper.safeGetInputs(categoriesFormRef),
                ValidationHelper.safeGetInputs(tiresFormRef),
                ValidationHelper.safeGetInputs(aluminiumWheelsFormRef),
                ValidationHelper.safeGetInputs(carsFormRef),
                ValidationHelper.safeGetInputs(motoFormRef),
                ValidationHelper.safeGetInputs(serversFormRef),
                ValidationHelper.safeGetInputs(businessInfoFormRef),
            );

            if (isValid) {
                isValid = ValidationHelper.validateInputs(inputs);
            }
            return isValid;
        };

        if (loading) {
            return <LoadingScreen />
        }
        if (!product) {
            return <NotFound />
        }

        const showTiresCard = product.vehicleType === VehicleType.SpareParts && product.subcategory === ProductSubcategory.Tires;

        const showMotoCard = ProductType.Car &&
            (product.vehicleType === VehicleType.Motorcycle || product.vehicleType === VehicleType.QuadBike);

        const showVehicleCard = product.type === ProductType.Car &&
            (product.vehicleType === VehicleType.Car ||
                product.vehicleType === VehicleType.Commercial || product.vehicleType === VehicleType.Truck);

        const showAluminiumWheelsCard = product.vehicleType === VehicleType.SpareParts &&
            (product.subcategory === ProductSubcategory.AluminumWheels || product.subcategory === ProductSubcategory.AluminumWheelsPlusTires);


        const getOldLinkToEdit = (): string => {
            if (product.type === ProductType.Others) {
                return `/nahravac/inzeraty/ostatni/${product.id}/upravit`;
            }
            else if (product.type === ProductType.Car) {
                return `/nahravac/inzeraty/auto/${product.id}/upravit`;
            }
        }

  
        const handleDeleteAllImages = () => {
           
            Swal.fire({
                icon: 'warning',
                title: "Opravdu chcete smazat všechny obrázky?",
                text: 'Tato akce je nevratná',
                confirmButtonText: "Ano, smazat",
                cancelButtonText: "Storno",
                showCancelButton: true,
                allowOutsideClick: true,
            }).then(async response => {
                if (response.value) {
                    setDelImagesLoading(true);
                    productApiService.deleteAllImages(product.id)
                        .then(data => {
                            if (data.success) {
                                toast.success("Obrázky smazány");
                            }
                        })
                        .finally(() => {
                            setDelImagesLoading(false);
                        })
                }
            });
        }

        let imagesLimitCount = 20;

        if (product.websiteId) {
            imagesLimitCount = 50;
        }

        return <>
            <LeavePageWarningPrompt when={blockLeave} />

            <div className="page-title-box">
                <h4 className="page-title">
                    <i className={product.type === ProductType.Others ? "fas fa-shopping-bag" : "fas fa-car"}></i> Úprava inzerátu - "{product.name}"
                </h4>
            </div> 

            { /*    <Alert variant={"info"} className="mt-2">
                <b>Nacházíte se v novém editačním rozhraní. Pokud něco chybí nebo nefunguje, prosím <Link to="/kontakt">dejte nám vědět</Link></b>. Děkujeme <a href={getOldLinkToEdit()}>
                    <button className="btn btn-outline-primary d-none d-lg-inline float-right">
                        Přepnout do původního rozhraní
                    </button>

                    <button className="btn btn-outline-primary d-lg-none mt-2">
                        Přepnout do původního rozhraní
                    </button>
                </a>
            </Alert>*/}

            <SelectMarketingChannelsCard
                ref={marketingChannelsRef}
                collection={collection}
                product={product}
                onChange={onChangeMarketingChannels}
                user={user}
                requiredFields={requiredFields}
            />

            <BasicInfoFormCard
                ref={basicFormRef}
                collection={collection}
                product={product}
                onChange={onChange}
                user={user}
                subcategories={subcategories}
                settings={globalSettings}
                requiredFields={requiredFields}
            />

            <PricesFormCard
                ref={pricesFormRef}
                collection={collection}
                onChange={onChange}
                product={product}
                settings={globalSettings}
                user={user}
                requiredFields={requiredFields}
                aiState={null}
            />

            <CategoriesFormCard
                ref={categoriesFormRef}
                onChange={onChange}
                product={product}
                user={user}
                requiredFields={requiredFields}
                subcategories={subcategories}
            />

            {showTiresCard &&
                <TiresFormCard
                    ref={tiresFormRef}
                    onChange={onChange}
                    product={product}
                    requiredFields={requiredFields}
                />}

            {showAluminiumWheelsCard &&
                <AluminiumWheelsFormCard
                    ref={aluminiumWheelsFormRef}
                    onChange={onChange}
                    product={product}
                    requiredFields={requiredFields}
                />}

            {showVehicleCard &&
                <CarsFormCard
                    ref={carsFormRef}
                    onChange={onChange}
                    product={product}
                    requiredFields={requiredFields}
                />}

            {showMotoCard &&
                <MotoFormCard
                    ref={motoFormRef}
                    onChange={onChange}
                    product={product}
                    requiredFields={requiredFields}
                />}

            <ServersFormCard
                ref={serversFormRef}
                onChange={onChange}
                product={product}
                user={user}
                requiredFields={requiredFields}
                fbCategories={fbCategories}
                googleCategories={googleCategories}
                subcategories={subcategories}
            />

            {user.doNotStoreBusinessData === false &&
                <BusinessInfoFormCard
                    ref={businessInfoFormRef}
                    onChange={onChange}
                    product={product}
                    user={user}
                    requiredFields={requiredFields}
                />}

            <div className="card">
                <div className="card-header">
                    <SectionTitle title={`OBRÁZKY`} className="my-0" /> 
                </div>
                <div className="card-body">
                    {images.length > 0 && < button
                        onClick={handleDeleteAllImages}
                        className="btn btn-outline-danger d-none d-lg-block float-right"
                    >
                        Smazat všechny obrázky
                    </button>}

                    <p>Práce s obrázky se automaticky ukládá, ale po dokončení úprav pro aplikaci změn na inzertních serverech je potřeba změny uložit. Můžete nahrát až {imagesLimitCount} obrázků. </p>

                    {images.length > 0 && <button
                        onClick={handleDeleteAllImages}
                        className="btn btn-outline-danger d-lg-none"
                    >
                        Smazat všechny obrázky
                    </button>}

                    {deleteImagesLoading && <WaitScreen
                        title="Probíhá mazání obrázků..."    
                    />}

                    <div className="row justify-content-center">
                        <div className="w-75">
                            <ProductImagesEditor
                                images={images}
                                product={product}
                                limitCount={imagesLimitCount}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <FormErrorSummary
                errors={formErrors}
            />

            <div className="mg-t-30 text-center">
                <div className="mt-4">
                    <div className="wizardStepNavigation form-layout-footer">
                        {!product.enabled && <>
                            <button
                                className="btn btn-success px-sm-4 btn-only-xs-block"

                                onClick={() => handleSaveChangesAsync(true)}
                            >
                                <i className="fa fa-upload mr-2"></i>
                                Uložit a zveřejnit</button>
                        </>}
                        <button
                            className="btn btn-primary px-sm-4 btn-only-xs-block"
                            onClick={() => handleSaveChangesAsync(false)}
                        >
                            <i className="fas fa-save mr-2"></i>
                            Uložit změny
                        </button>
                    </div>
                </div>
            </div>
        </>
    }))