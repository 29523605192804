import IProductImage from '../../../../Interfaces/IProductImage';
import Dropzone from 'react-dropzone';
import { orderBy, take } from 'lodash';
import IProduct from '../../../../Interfaces/IProduct';
import { ImagesSortableList } from '../../../Products/Images/ImagesSortableList';
import { uploadImages } from '../../../Products/Images/uploadImages';
import { WaitScreen } from '../../../Screens/WaitScreen';
import { useState } from 'react';

interface IProps {
    images: IProductImage[],
    product: IProduct,
    limitCount?: number,
}

export const ProductImagesEditor = (props: IProps) => {

    const { product, limitCount } = props;
    const images = orderBy(props.images, ['orderNumber'], ['asc']);

    const [uploadImageLoading, setUploadImageLoading] = useState(false);

    let canBeUploadedCount = limitCount - images.length;

    if (canBeUploadedCount < 0) {
        canBeUploadedCount = 0;
    }

    const uploadImagesProcess = (images: File[], p: IProduct) => {
        setUploadImageLoading(true);
        console.log(canBeUploadedCount);
        uploadImages(take(images, canBeUploadedCount), p)
            .then(() => {
                setUploadImageLoading(false);
            })
    }

    const enableUploadImages = images.length < limitCount;

    return (
        <div>
            {enableUploadImages && <Dropzone disabled={!enableUploadImages} onDrop={(files) => uploadImagesProcess(files, product)}>
                {({ getRootProps, getInputProps, }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} accept="image/x-png,image/jpeg" />
                            <div className={`dropzone-area my-4 ${enableUploadImages ? '' : 'disabled'}`}>
                                <h3>Vybrat obrázky</h3>                     
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>}

            {!enableUploadImages &&
                <div className="alert alert-warning mt-2">
                    <p className="text-center m-0">Nahráli jste maximum obrázků ({images.length}/{limitCount})</p>
                </div>}


            {uploadImageLoading &&
                <WaitScreen
                    title="Nahrávám obrázky..."
                />}

            <ImagesSortableList
                images={images}
                product={product}
            />
        </div>
    )
}