import { Provider } from 'mobx-react';
import CurrentUserStore from './components/Stores/CurrentUserStore';
import { Outlet } from 'react-router-dom';
import ProductStore from './components/Stores/ProductStore';
import ProductCollectionStore from './components/Stores/ProductCollectionStore';
import ProductImageStore from './components/Stores/ProductImageStore';
import AppIpAddressStore from './components/Stores/AppIpAddressStore';
import ProductLogStore from './components/Stores/ProductLogStore';
import GlobalSettingsStore from './components/Stores/GlobalSettingsStore';
import AutoImporterStore from './components/Stores/AutoImporterStore';
import FacebookCatalogStore from './components/Stores/FacebookCatalogStore';
import WebsiteStore from './components/Stores/WebsiteStore';
import AccountTransactionStore from './components/Stores/AccountTransactionStore';
import WebmailStore from './components/Stores/WebmailStore';
import ProformaInvoiceStore from './components/Stores/ProformaInvoiceStore';
import BazosCouponStore from './components/Stores/BazosCouponStore';
import ServerAccountStore from './components/Stores/ServerAccountStore';
import ListsStore from './components/Stores/ListsStore';
import ProductServerAccountStore from './components/Stores/ProductServerAccountStore';
import 'react-toastify/dist/ReactToastify.css';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';

export const currentFrontEndVersion = 65;

export const currentUserStore: CurrentUserStore = new CurrentUserStore();
export const productStore: ProductStore = new ProductStore();
export const productCollectionStore: ProductCollectionStore = new ProductCollectionStore();
export const productImageStore: ProductImageStore = new ProductImageStore();
export const appIpAddressStore: AppIpAddressStore = new AppIpAddressStore();
export const productLogStore: ProductLogStore = new ProductLogStore();
export const globalSettingsStore: GlobalSettingsStore = new GlobalSettingsStore();
export const autoImporterStore: AutoImporterStore = new AutoImporterStore();
export const facebookCatalogStore: FacebookCatalogStore = new FacebookCatalogStore();
export const websiteStore: WebsiteStore = new WebsiteStore();
export const accountTransactionStore: AccountTransactionStore = new AccountTransactionStore();
export const webmailStore: WebmailStore = new WebmailStore();
export const proformaInvoiceStore: ProformaInvoiceStore = new ProformaInvoiceStore();
export const bazosCouponStore: BazosCouponStore = new BazosCouponStore();
export const serverAccountStore: ServerAccountStore = new ServerAccountStore();
export const listsStore: ListsStore = new ListsStore();
export const productServerAccountStore: ProductServerAccountStore = new ProductServerAccountStore();

Chart.register(CategoryScale);

function App() {
    return (
        <Provider
            currentUserStore={currentUserStore}
            productStore={productStore}
            productCollectionStore={productCollectionStore}
            productImageStore={productImageStore}
            appIpAddressStore={appIpAddressStore}
            productLogStore={productLogStore}
            globalSettingsStore={globalSettingsStore}
            autoImporterStore={autoImporterStore}
            facebookCatalogStore={facebookCatalogStore}
            websiteStore={websiteStore}
            accountTransactionStore={accountTransactionStore}
            webmailStore={webmailStore}
            proformaInvoiceStore={proformaInvoiceStore}
            bazosCouponStore={bazosCouponStore}
            listsStore={listsStore}
            serverAccountStore={serverAccountStore}
            productServerAccountStore={productServerAccountStore}
        >
            <Outlet />
        </Provider>
    );
}
export default App;